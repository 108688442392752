import state from "./moduleCalendarState"
import getters from "./moduleCalendarGetters"
import mutations from "./moduleCalendarMutaions"
import actions from "./moduleCalendarActions"
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
